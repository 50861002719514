<template>
  <v-app id="app-wrapper">
    <v-app-bar
      id="topbar-post-login"
      color="transparent"
      dark
      dense
      fixed
      prominent
    >
      <v-toolbar-title class="d-flex">
        <template>
          <router-link :to="'/app'">
            <img
              class="app-icon"
              src="/img/brasao_64.png"
              alt="brasão-home-64px"
            />
          </router-link>
          <span v-text="$appName" class="ml-5"></span>
        </template>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <template>
        <v-subheader class="white--text" v-text="$user.fullName"></v-subheader>
      </template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="solicitarRecuperacaoSenha()"
          >
            <v-icon>mdi-lock-reset</v-icon>
          </v-btn>
        </template>
        Trocar Senha
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-4 ml-4"
            icon
            v-bind="attrs"
            v-on="on"
            @click="logout"
          >
            Sair
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        Sair
      </v-tooltip>
      <template v-slot:extension>
        <template>
          <v-menu v-for="(item, i) in menu" :key="i" open-on-hover offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                color="transparent"
                small
                v-bind="attrs"
                v-on="on"
                v-text="item.name"
              ></v-btn>
            </template>
            <v-list dense flat>
              <v-list-item-group color="primary">
                <v-list-item
                  dense
                  :to="menu.url"
                  v-for="(menu, menuIndex) in item.items"
                  :key="menuIndex"
                >
                  <v-list-item-icon v-if="menu.icon != null"
                    ><v-icon v-text="menu.icon"></v-icon
                  ></v-list-item-icon>
                  <v-list-item-title v-text="menu.name"></v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </template>
    </v-app-bar>
    <CurrentPageMenuComponent></CurrentPageMenuComponent>
    <v-main id="main">
      <v-container fluid>
        <v-row v-if="deferredPrompt">
          <v-col>
            <v-banner dark color="info">
              <div class="d-flex align-center font-weight-light">
                <v-icon color="white" class="mr-4"
                  >mdi-information-outline</v-icon
                >
                <span
                  >Obtenha nosso aplicativo gratuito. Não vai ocupar espaço no
                  seu telefone e também funciona offline!</span
                >
              </div>
              <template #actions>
                <v-btn small text class="ml-5" @click="dismissPwa"
                  >Ignorar</v-btn
                >
                <v-btn small text @click="installPwa">Instalar</v-btn>
              </template>
            </v-banner>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <vue-page-transition name="fade-in-right">
              <router-view
                class="page"
                @onDrawerToggled="onDrawerToggled"
                :drawer="drawer"
              />
            </vue-page-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <MudarSenhaComponent
      :visible="trocarSenha.visible"
      :content="trocarSenha"
      @onClose="trocarSenha.visible = false"
    ></MudarSenhaComponent>
  </v-app>
</template>

<script>
import { AUTH_NAMESPACE, MENU } from "store/modules/auth";
import CurrentPageMenuComponent from "components/CurrentPageMenuComponent.vue";
import MudarSenhaComponent from "components/MudarSenhaComponent.vue";
import { TrocarSenha } from '~/commons/classes';

const pwaDeferrerdPromptKey = "pwa-add-to-home-screen";

export default {
  name: "PostLoginLayout",
  components: {
    CurrentPageMenuComponent,
    MudarSenhaComponent,
  },
  data: () => ({
    deferredPrompt: null,
    drawer: null,
    trocarSenha: TrocarSenha.Build(),
  }),
  computed: {
    menu() {
      return this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`];
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      const pwaPrompCache = localStorage.getItem(pwaDeferrerdPromptKey);
      if (pwaPrompCache === "" || pwaPrompCache === null) {
        this.deferredPrompt = e;
      }
    });

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async onDrawerToggled(state) {
      this.drawer = state;
    },
    async logout() {
      this.$router.push({ path: "/login" });
    },
    async dismissPwa() {
      localStorage.setItem(pwaDeferrerdPromptKey, null);
      this.deferredPrompt = null;
    },
    async installPwa() {
      this.deferredPrompt.prompt();
    },
    solicitarRecuperacaoSenha() {
      const objEnvio = {
        nomeDeUsuario: this.$user.username
      }
      this.$solicitarTrocaDeSenha(objEnvio).then(({ data }) => {
        this.trocarSenha = new TrocarSenha({
          ...data,
          nomeDeUsuario: objEnvio.nomeDeUsuario
        });
        this.trocarSenha.visible = true;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
#app-wrapper {
  background: #f5f5f5;

  #background-image {
    position: fixed;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
:deep(#topbar-post-login) {
  &::before {
    content: "";
    display: none;
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(47, 143, 204);
  }
  .v-image__image.v-image__image--cover {
    background-position: 0 0 !important;
  }
  .app-icon {
    max-height: 32px;
  }
}

#sidebar {
  margin-top: 144px;
  z-index: 1;
}

#main {
  margin-top: 144px !important;
  .page {
    min-height: 75vh;
  }
}
</style>