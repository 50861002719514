var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "app-wrapper"
    }
  }, [_c('v-app-bar', {
    attrs: {
      "id": "topbar-post-login",
      "color": "transparent",
      "dark": "",
      "dense": "",
      "fixed": "",
      "prominent": ""
    },
    scopedSlots: _vm._u([{
      key: "extension",
      fn: function () {
        return [_vm._l(_vm.menu, function (item, i) {
          return _c('v-menu', {
            key: i,
            attrs: {
              "open-on-hover": "",
              "offset-y": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref) {
                var on = _ref.on,
                    attrs = _ref.attrs;
                return [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "elevation": "0",
                    "color": "transparent",
                    "small": ""
                  },
                  domProps: {
                    "textContent": _vm._s(item.name)
                  }
                }, 'v-btn', attrs, false), on))];
              }
            }], null, true)
          }, [_c('v-list', {
            attrs: {
              "dense": "",
              "flat": ""
            }
          }, [_c('v-list-item-group', {
            attrs: {
              "color": "primary"
            }
          }, _vm._l(item.items, function (menu, menuIndex) {
            return _c('v-list-item', {
              key: menuIndex,
              attrs: {
                "dense": "",
                "to": menu.url
              }
            }, [menu.icon != null ? _c('v-list-item-icon', [_c('v-icon', {
              domProps: {
                "textContent": _vm._s(menu.icon)
              }
            })], 1) : _vm._e(), _c('v-list-item-title', {
              domProps: {
                "textContent": _vm._s(menu.name)
              }
            })], 1);
          }), 1)], 1)], 1);
        })];
      },
      proxy: true
    }])
  }, [_c('v-toolbar-title', {
    staticClass: "d-flex"
  }, [[_c('router-link', {
    attrs: {
      "to": '/app'
    }
  }, [_c('img', {
    staticClass: "app-icon",
    attrs: {
      "src": "/img/brasao_64.png",
      "alt": "brasão-home-64px"
    }
  })]), _c('span', {
    staticClass: "ml-5",
    domProps: {
      "textContent": _vm._s(_vm.$appName)
    }
  })]], 2), _c('v-spacer'), [_c('v-subheader', {
    staticClass: "white--text",
    domProps: {
      "textContent": _vm._s(_vm.$user.fullName)
    }
  })], _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.solicitarRecuperacaoSenha();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-lock-reset")])], 1)];
      }
    }])
  }, [_vm._v(" Trocar Senha ")]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mr-4 ml-4",
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.logout
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Sair "), _c('v-icon', [_vm._v("mdi-logout-variant")])], 1)];
      }
    }])
  }, [_vm._v(" Sair ")])], 2), _c('CurrentPageMenuComponent'), _c('v-main', {
    attrs: {
      "id": "main"
    }
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.deferredPrompt ? _c('v-row', [_c('v-col', [_c('v-banner', {
    attrs: {
      "dark": "",
      "color": "info"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "ml-5",
          attrs: {
            "small": "",
            "text": ""
          },
          on: {
            "click": _vm.dismissPwa
          }
        }, [_vm._v("Ignorar")]), _c('v-btn', {
          attrs: {
            "small": "",
            "text": ""
          },
          on: {
            "click": _vm.installPwa
          }
        }, [_vm._v("Instalar")])];
      },
      proxy: true
    }], null, false, 3671279398)
  }, [_c('div', {
    staticClass: "d-flex align-center font-weight-light"
  }, [_c('v-icon', {
    staticClass: "mr-4",
    attrs: {
      "color": "white"
    }
  }, [_vm._v("mdi-information-outline")]), _c('span', [_vm._v("Obtenha nosso aplicativo gratuito. Não vai ocupar espaço no seu telefone e também funciona offline!")])], 1)])], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('vue-page-transition', {
    attrs: {
      "name": "fade-in-right"
    }
  }, [_c('router-view', {
    staticClass: "page",
    attrs: {
      "drawer": _vm.drawer
    },
    on: {
      "onDrawerToggled": _vm.onDrawerToggled
    }
  })], 1)], 1)], 1)], 1)], 1), _c('MudarSenhaComponent', {
    attrs: {
      "visible": _vm.trocarSenha.visible,
      "content": _vm.trocarSenha
    },
    on: {
      "onClose": function ($event) {
        _vm.trocarSenha.visible = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }