var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.currentPageMenu && _vm.currentPageMenu.items ? _c('v-navigation-drawer', {
    attrs: {
      "id": "sidebar",
      "app": "",
      "floating": "",
      "fixed": "",
      "clipped": "",
      "permanent": _vm.permanentSidebar,
      "color": _vm.sidebarColor,
      "src": _vm.sidebarBackgroundImage
    },
    on: {
      "input": _vm.onDrawerToggled
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [!_vm.permanentSidebar ? [_c('v-list', {
    attrs: {
      "shaped": ""
    }
  }, _vm._l(_vm.menu, function (item, index) {
    return _c('v-list-group', {
      key: index,
      attrs: {
        "no-action": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function () {
          return [_c('v-list-item-icon', [item.icon != null ? _c('v-icon', {
            domProps: {
              "textContent": _vm._s(_vm.menu.icon)
            }
          }) : _vm._e()], 1), _c('v-list-item-title', {
            domProps: {
              "textContent": _vm._s(item.name)
            }
          })];
        },
        proxy: true
      }], null, true)
    }, _vm._l(item.items, function (child, i) {
      return _c('v-list-item', {
        key: i,
        attrs: {
          "link": "",
          "to": child.url
        }
      }, [_c('v-list-item-icon', [_c('v-icon', {
        domProps: {
          "textContent": _vm._s(child.icon)
        }
      })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
        domProps: {
          "textContent": _vm._s(child.name)
        }
      })], 1)], 1);
    }), 1);
  }), 1)] : _vm.currentPageMenu ? [_c('v-list', {
    attrs: {
      "shaped": ""
    }
  }, [_c('v-subheader', {
    domProps: {
      "textContent": _vm._s(_vm.currentPageMenu.name)
    }
  }), _vm._l(_vm.currentPageMenu.items, function (item, i) {
    return _c('v-list-item', {
      key: i,
      attrs: {
        "link": "",
        "to": item.url
      }
    }, [item.icon != null ? _c('v-list-item-icon', [_c('v-icon', {
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.name)
      }
    })], 1)], 1);
  })], 2)] : _vm._e()], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }