<template>
  <v-navigation-drawer
    id="sidebar"
    app
    floating
    fixed
    clipped
    :permanent="permanentSidebar"
    :color="sidebarColor"
    :src="sidebarBackgroundImage"
    v-model="drawer"
    v-if="currentPageMenu && currentPageMenu.items"
    @input="onDrawerToggled"
  >
    <template v-if="!permanentSidebar">
      <v-list shaped>
        <v-list-group v-for="(item, index) in menu" :key="index" no-action>
          <template v-slot:activator>
            <v-list-item-icon
              ><v-icon v-text="menu.icon" v-if="item.icon != null"></v-icon
            ></v-list-item-icon>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </template>

          <v-list-item
            link
            :to="child.url"
            v-for="(child, i) in item.items"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon v-text="child.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="child.name"> </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </template>
    <template v-else-if="currentPageMenu">
      <v-list shaped>
        <v-subheader v-text="currentPageMenu.name"></v-subheader>
        <v-list-item
          link
          :to="item.url"
          v-for="(item, i) in currentPageMenu.items"
          :key="i"
        >
          <v-list-item-icon v-if="item.icon != null">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.name"> </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { AUTH_NAMESPACE, MENU } from "store/modules/auth";
import {
  BACKGROUND_IMAGE,
  PRE_LOGIN_LAYOUT_NAMESPACE,
} from "~/store/modules/pre-login-layout";

export default {
  name: "CurrentPageMenuComponent",
  data: () => ({
    drawer: null,
  }),
  computed: {
    backgroundImageSrc() {
      return this.$store.getters[
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${BACKGROUND_IMAGE}`
      ];
    },
    menu() {
      return this.$store.getters[`${AUTH_NAMESPACE}/${MENU}`];
    },
    currentPageMenu() {
      return this.menu?.find(
        (m) =>
          (m.url &&
            m.url.toLocaleLowerCase() ===
              this.$route.fullPath.toLocaleLowerCase()) ||
          (m.items &&
            m.items.find(
              (child) =>
                child.url &&
                child.url.toLocaleLowerCase() ===
                  this.$route.fullPath.toLocaleLowerCase()
            ))
      );
    },
    permanentSidebar() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    sidebarBackgroundImage() {
      return !this.permanentSidebar ? this.backgroundImageSrc : null;
    },
    sidebarColor() {
      return this.permanentSidebar ? "transparent" : "white";
    },
  },
  methods: {
    async onDrawerToggled(state) {
      this.drawer = state;
    },
  },
};
</script>